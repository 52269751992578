<template>
    <MainFunctions :buttons="buttons" :title="$t('menu.nav-washhome-title')"></MainFunctions>
</template>

<script>
import store from '@/store'
import MainFunctions from "@/components/MainFunctions"
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'WashHostMenu',
    components: {
        MainFunctions
    },
    data() {
        store.commit('setPageHome', HOMETYPES.WASH)
        return {
            buttons: [
                { title: 'menu.buttons.washclerk-takeout', color: "cyan", icon: 'local_shipping', routeName: 'washClerkTakeout'},
                { title: 'menu.buttons.washclerk-putin', color: "green", icon: 'replay', routeName: 'washClerkPutin'},
                { title: 'menu.buttons.washclerk-print-list', color: "deep-orange accent-3", icon: 'print', routeName: 'washClerkPrintList'},
                { title: 'menu.buttons.washclerk-revise-list', color: "lime darken-3", icon: 'move_up', routeName: 'washRevisePutin'}
            ]
        }
    },
    activated() {
        store.commit('setPageHome', HOMETYPES.WASH)
    }
}
</script>
